import React, { useEffect } from "react";
import "./Slider.css";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

const Slider = ({ data, variant = 1 }) => {
  const redirect = (link) => {
    window.location =
      "https://n1142833.yclients.com/company/1052557/personal/select-services" +
      link;
  };

  const redirect_v2 = (link) => {
    window.location =
      "https://n1142833.yclients.com/company/1052557/personal/select-master" +
      link;
  };

  const redirect_v3 = (link) => {
    window.location =
      link;
  };

  const redirect_master = (id) => {
    window.location.href = `/master/${id}`;
  };

  const handleCardClick = (value, link) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "UNIVERSAL_EVENT",
      eventName: "services_click_button",
      url: "https://macik.beauty/"
    });
    if(variant === 3){
      redirect_v3 (link);
    }
    else{
      redirect(link);
    }
  };

  const handleMasterCardClick = (value) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "UNIVERSAL_EVENT",
      eventName: "master_appointment_click_button",
      url: "https://macik.beauty/"
    });

    redirect_master(value.id);
  };

  return (
    <div className={"Slider"}>
      <Splide
        className={"Slider__splide"}
        hasTrack={false}
        options={{
          width: "100%",
          pagination: false,
          autoWidth: true,
          gap: "20px",
          focus: "start",
          rewind: false,
          omitEnd: true,
        }}
      >
        <div
          className={
            variant === 2 ? "Slider__title Slider__title_v2" : "Slider__title"
          }
        >
          <p className={"Slider__title__text"}>
            {variant === 2
              ? data.title
              : data.title + " (" + data.cards.length + ")"}
          </p>
          <div className={"Slider__title__arrow splide__arrows"}>
            <button className="splide__arrow splide__arrow--prev"></button>
            <button className="splide__arrow splide__arrow--next"></button>
          </div>
        </div>

        <SplideTrack>
          {variant === 2
            ? data.cards.map((value, index) => {
              return (
                <SplideSlide
                  key={"Slider__card_v2__" + index}
                  onClick={() => {
                    // Проверяем параметр master перед вызовом функции
                    if (value.master === true) {
                      //redirect_master(value.id);
                      handleMasterCardClick(value);
                    }
                  }}
                >
                  <div className={"Slider__card_v2"}>
                    <img
                      className={"Slider__card__img_v2"}
                      src={value.img_url}
                      alt={value.img_url}
                      loading="lazy"
                    />
                    <h3 className={"Slider__card__title_v2"}>
                      {value.title}
                    </h3>
                    <p className={"Slider__card__description_v2"}>
                      {value.position}
                    </p>
                    {/* 
                                        <p className={'Slider__card__experience_v2'}>Опыт работы {value.experience}</p>
                                        */}
                    <button
                      className={"Slider__card__button_v2"}
                      onClick={(e) => {
                          e.stopPropagation();
                          redirect_v2(value.link);
                      }}
                    >
                      Записаться
                    </button>
                  </div>
                </SplideSlide>
              );
            })
            : data.cards.map((value, index) => {
              return (
                <SplideSlide
                  key={"Slider__card__" + index}
                  onClick={() => handleCardClick(value, value.link)}
                >
                  <div className={"Slider__card"}>
                    <div>
                      <img
                        className={"Slider__card__img"}
                        src={value.img_url}
                        alt={value.img_url}
                        loading="lazy"
                      />
                      <h3 className={"Slider__card__title"}>
                        {value.title.split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            {index < value.title.split("\n").length - 1 && (
                              <br />
                            )}
                          </React.Fragment>
                        ))}
                      </h3>
                    </div>
                    {value.cost !== "" ? (
                      <p className={"Slider__card__coast"}>
                        {value.cost + " ₽"}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </SplideSlide>
              );
            })}
        </SplideTrack>
      </Splide>
    </div>
  );
};

export default Slider;
